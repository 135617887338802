@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  color: #56595c;
  font-size: 14px;
  font-family: open sans, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  overflow-x: hidden;
}
a{
  text-decoration: none !important;
}

/* Header and Navbar */
.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}
nav.desktop-nav {
  margin-left: -16%;
  margin-top: 25px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}
/* header-phone */

.header-phone a{
  color: #E56018;
}

.text-center.mt-5.mb-5.hero-banner-sub-text {
  margin-top: 33px !important;
}

img.homeaboutimage.jumping-animation {
  width: 70%;
  margin-top: -10%;
  margin-left: 25%;
}



/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  text-transform: uppercase;
  font-weight: 600;
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 14px;
  margin-top: -11px;
}

.desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color:#CBCCCE ;
  text-decoration: none;
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
}

.desktop-nav button span {
  margin-left: 3px;
}

.desktop-nav .menu-items > a, .desktop-nav .menu-items button {
  text-align: left;
  padding: 0.7rem 1.5rem;
  font-size: 17px;

}

.desktop-nav .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.desktop-nav .dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.desktop-nav .dropdown.show {
  display: block;
  color: #CBCCCE;
  min-width: 300px;
  border-bottom: 1px solid 000; 
}
.desktop-nav .dropdown.show a{
  text-align: justify;
}

.desktop-nav .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
    color: #CBCCCE;
    /* height: 50vh;
    overflow-y: scroll; */
    
}

.desktop-nav .dropdown .dropdown-submenu .menu-items > a {
  padding: 1rem;
  border-bottom: 1px solid #000;
}

.desktop-nav .dropdown .dropdown-submenu .menu-items > a:not(:last-child) {
  border-bottom: none;
}


.desktop-nav .dropdown .menu-items button {
  text-align: left;
  padding: 15px;
  font-size: 17px;
  border-bottom: 1px solid #000;
}

.mobile-nav {
  display: none;
}
.hero-banner-sub-text-top{
  display: none;
}

/* menu on mobile */
@media screen and (max-width: 960px) {
  .hero-banner-sub-text-top {
    display: block;
    margin-top: -27% !important;
    margin-bottom: 46% !important;
}
img.homeaboutimage.jumping-animation {
  width: 100%;
  margin-top: 1%;
  margin-left: 0;
}



  .header-phone a{
    display: none;
  }
  :where(.css-1qfezbu).ant-carousel {
    display: none !important;
}
  .nav-area {
    justify-content: space-between;
    background: #fff !important;
    height: 0;
    margin-left: -36px;
}
  .responsive-header {
    color: #000 !important;
  }
 .header-logo {
    width: 180px !important;
    margin-top: 46% !important;
  }
  .banner-image {
    display: block;
    width: 100%;
    height: 220px !important;
  }

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .mobile-nav {
    display: block;
  }
  .mobile-nav .mobile-nav__menu-button {
    color: #CBCCCE;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    margin-top: 28px;
    margin-left: 85%;
}
  .mobile-nav .menus {
    list-style: none;
    position: absolute;
    top: 50px;
    right: 20px;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .mobile-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .mobile-nav .menu-items > a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
    background-color: #f2f2f2;
  }

  .mobile-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  .mobile-nav .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }
  .mobile-nav .dropdown .dropdown-submenu {
    height: 20vh;
    overflow-y: scroll;
}
.text-center.mt-5.mb-5.hero-banner-sub-text {
  margin-top: -5px !important;
  display: none;
}

}


/* hero */
.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff; /* Change the background color as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for a nice effect */
}
.desktop-view-hero-btn{
  display: none;
}

.hero h1 {
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #E56018;
}
.hero h3 {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #0081C7;
  margin-top: 40px;
}
.left-btn, .right-btn, .bottom-btn {
  margin-right: 30px;
  width: 300px;
  background: #E56018;
  border: none;
  border-radius: 30px;
  margin-top: 37px;
  padding: 13px;
  outline: none;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
}

.hero .left-btn,.right-btn:hover{
  border: none;
  outline: none;
}

.bottom-btn {
  width: 400px !important;
  margin-bottom: 40px;
  margin-top: 15px !important;
}




/* about-section */

.hm-about-sec{
  display: block;
  clear: both;
  position: relative;
  padding-top: 40px;
  padding-bottom: 20px;
}

.hm-about-title {
  margin-bottom: 40px;
  text-align: center;
}

.hm-about-sec p {
  font-size: 18px;
  margin: 0;
  text-align: justify;
  padding: 20px;
  letter-spacing: 1px;
  line-height: 35px;
}

.hm-about-sec h2 {
  font-size: 35px;
  position: relative;
  text-align: center;
  color: #050521;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
}
.hm-about-sec h2:after {
  content: '';
  display: block;
  width: 200px;
  height: 3px;
  background: #E56018;
  left: 0;
  right: 0;
  margin: 4px auto 0;
  position: absolute;
  top: 100%;
}
.hm-about-what-sec h2:after {
  content: '';
  display: block;
  width: 80px;
  height: 3px;
  background: #E56018;
  left: 0;
  right: 0;
  margin: 4px auto 0;
  position: absolute;
  top: 100%;
}

.hm-about-sec h2 span {
  color: #E56018;
  font-weight: 700;
}


.btn-red {
  color: #fff;
  background-color: #E56018;
  border-color: #E56018;
  display: inline-block;
  font-size: 16px;
  padding: 8px 35px;
  line-height: 1.5;
  margin-top: 20px;
  text-decoration: none;
}

.btn-red:hover {
  background-color: #E56018;
  border-color: #E56018;
  color: #fff;
  text-decoration: none;
}

.aboutimage {
  margin-left: 15%;
  width: 70%;
  margin-top: 45px;
}

/* .jumping-animation {
  animation: jump 0.5s infinite alternate; 
} */

@keyframes jump {
  from {
    transform: translateY(0); /* Start from original position */
  }
  to {
    transform: translateY(-10px); /* Jump up by 10 pixels */
  }
}
.about-list {
  list-style: none;
  padding: 0;
  margin-top: -10%;
}

/* Define animation for list items */
.animated-list-item {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.about-list li {
  list-style-type: none;
}

.about-list li {
  font-size: 3rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 136%;
  color: #0C0649;
  margin-bottom: 25px;
  letter-spacing: 10px;
  cursor: pointer;
}
.animated-list-item {
  z-index: -1;
  height: 60px;
  width: 0%;
  cursor: pointer;
}

.animated-list-item:hover{
  color: #E35C18;
}



/* service-section */
.hm-services-sec {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 25%, transparent 75%) ,url("./assets/images/aboutbanner.jpg");
  height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  
}

.hm-services-title {
  margin-bottom: 40px;
  text-align: center;
}

.hm-services-title p {
  font-size: 16px;
  margin: 0;
}

.hm-services-sec h2 {
  font-size: 35px;
  position: relative;
  text-align: center;
  color: #fff;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
}

/* card */
.grid-container {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Adjust column width as needed */
  gap: 20px; /* Adjust gap between grid items as needed */
}

.card-container {
  flex: 300px;
  margin: 10px;
}
.card-container .card {
  font-weight: bold;
  position: relative;
  width: 100%;
}
.card-container .card a {
  padding: 30px;
  width: 100%;
  height: 250px;
  border: 2px solid #ccc;
  background: white;
  text-decoration: none;
  color: gray;
  display: block;
  transition: 0.25s ease;
}
.card--display {
  font-size: 25px;
  margin-left: 40%;
}
.card--display h2 {
  font-size:25px;
  color: #E56018;
  margin-left: -70% !important;
}
.card-container .card a .card--hover h2 {
  margin: 20px 0;
  color: #E56018;
  font-size: 25px;
}
.card-container .card a:hover {
  transform: translate(-30px, -30px);
  border-color: #E56018;
  border-radius: 15px;
  height: 310px;

}
.card-container .card a:hover .card--display {
  display: none;
}
.card-container .card a:hover .card--hover {
  display: block;
  border-radius: 15px;
}
.card-container .card a .card--display h2 {
  margin: 20px 0 0;
}
.card-container .card a .card--hover {
  display: none;

}
.card-container .card a .card--hover p {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.5;
  color: grey;
  font-weight: 500;
  text-align: justify;
}
.card-container .card a .card--hover p.link {
  margin: 20px 0 0;
  font-weight: bold;
  color: #E56018;
  text-align: justify;
}

/* News Section  */
.news-block-sec {
  display: block;
  clear: both;
  position: relative;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  background: #343a85;
  padding-top: 40px;
  padding-bottom: 30px;
}
.news-block-sec:before {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  content: "";
}

.news-block-sec h2 {
  text-align: center;
  line-height: 36px;
  padding-bottom: 30px;
  font-size: 30px;
  margin: 0;
  color: #fff;
}

.news-block-sec h2 span {
  color: #fff;
  font-weight: 600;
  font-size: 36px;
}

.news-item-box {
  margin-bottom: 30px;
  padding: 20px 20px;
  background-color: #fff;
  transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, -webkit-border-radius .3s, -webkit-box-shadow .3s;
}

.news-item-box h3 {
  font-size: 24px;
  margin: -20px -20px 30px;
  background: #050521;
  padding: 10px 0;
  color: #fff;
  position: relative;
  text-align: center;
}

.news-item-box h3:after {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #050521;
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 0;
  right: 0;
  margin: auto;
  top: 100%;
}

.duedate td {
  border-bottom: 1px dashed #ccc;
  padding: 5px
}

.duedate td table td {
  border: 0;
  padding-right: 20px;
  font-weight: bold;
}

.duedate .dtblgo {
  border-bottom: 1px dashed #ccc;
  padding: 5px
}

.duedate .datrtl {
  font-weight: bold;
}

.duedate .dtblgo:last-child {
  border: 0;
}

#ctl00_DataListGovind a {
  font-weight: bold;
  padding-right: 25px;
  font-size: 12px;
  color: #1b396b;
}

.news_blog {
  border-bottom: 1px dashed #ccc;
  color: #000;
  display: block;
  padding: 5px 0 5px;
}

.arrownews {
  padding: 5px 0 5px;
  display: block;
}

.news2d a {
  border-bottom: 1px dashed #ccc;
  color: #000;
  display: block;
  padding: 5px 0px 5px 0px;
  font-weight: normal;
}

.hedingnews,
a.hedingnews {
  display: block;
  font-weight: bold;
  border: 0;
  padding: 0;
}

.boxstylenew_call .alternate {
  padding: 5px;
}

.boxstylenew {
  float: left;
  width: 100%;
  padding-top: 13px;
}

.boxstylenew_call {
  width: 32.5%;
  margin-right: 5px;
  float: left;
  border: 1px solid #2b4081;
  height: 332px;
}

/* support-section */
.support-section{
  padding: 20px 0;
  margin: 4%;
}

.support-section h1 {
  text-align: center;
  font-size: 35px;
  color: #4169E1;

}

/* Support card */

.grid-support-container > div {
  margin-top: 40px;
  display: inline-flex;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-left: 10%;
}

.supportcard {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: 250px;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
}

.support-Limited-card {
  width: 100%;
  background-color: #fff;
  margin: 10px;
  border-radius: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 50vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 30px;
}
.support-Limited-card .title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color:#050494;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.support-Limited-card:hover{
  border: 1px solid #E56018;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.supportcard .title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color:#050494;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.supportcard .text {
  width: 80%;
  margin: 0 auto;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  color: grey;
  font-weight: 200;
  letter-spacing: 2px;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease;
}

.supportcard:hover {
  height: 420px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  min-width: 318px;
}
.supportcard .icon {
 font-size: 40px;
}
.supportcard .icon :hover {
  margin-top: -10%;
}

.supportcard:hover .info {
  height: 90%;
}

.supportcard:hover .text {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: 80px;
  height: 100%;
}

.supportcard:hover .icon {
  background-position: -120px;
  transition: all 0.3s ease;
}


/* footer */

footer {
  background: #fff;
  color: #0081C7;
  display: block;
  clear: both;
  position: relative;
  padding-top: 10px;
}
.footer-logo img{
  margin-left: 13%;
}

.footer.icon {
  margin-left: -9px;
  font-size: 27px;
  margin-top: 20px;
}

.foote-link h2 {
  color: #0081C7;
  margin: 0 0 24px;
  position: relative;
  font-size: 14px;
  font-weight: 700;
}

.foote-link {
  padding-top: 40px;
  padding-bottom: 0px;
  display: block;
  clear: both;
}

.foote-link ul.foot-link-list {
  list-style-type: none;
  margin: 0;
  padding: 0px;
  font-size: 14px;
}

.foote-link ul.foot-link-list li {
  position: relative;
  color: #f9f9f9;
}

.foote-link ul.foot-link-list li a {
  text-transform: capitalize;
  line-height: 26px;
  color: #f9f9f9;
  padding: 3px 0;
  display: block;
  transition: all 0.3s;
  position: relative;
  padding-left: 20px;
  text-decoration: none;
}

.foote-link ul.foot-link-list li a:before {
  content: '\2192';
  position: absolute;
  left: 0;
  top: 3px;
  font: 400 18px/24px 'FontAwesome';
  color: #fff;
  transition: .33s all ease;
  margin-left: -6px;
}

.foote-link ul.foot-link-list li a:hover:before {
  left: 4px;
}

.foote-link ul.about-list li .ab-pd {
  line-height: 20px;
  overflow: hidden;
  display: block;
  color: #000;
}

.foote-link ul.about-list li.call-mail a {
  color: #f9f9f9;
}

.foote-link .form-control {
  height: 43px;
  margin-bottom: 15px;
  border-radius: 0;
  border: 1px solid #000;
  color: #000;
  padding-left: 10px;
  background: #fff;
}

.btn-join {
  background: #ccc;
  border-radius: 0;
  font-size: 16px;
  text-transform: uppercase;
  height: 43px;
  padding-left: 25px;
  padding-right: 25px;
  color: #fff;
  cursor: pointer;
}

.btn-join:hover,
.btn-join:focus {
  background: #001f2e;
  color: #fff;
}

.foote-link .form-control::-moz-placeholder {
  color: #222327;
  opacity: 1;
}

.copy-right {
  padding-top: 25px;
  padding-bottom: 20px;
  margin-top: 30px;
  background: #fff;
  display: block;
  clear: both;
}

.copy-right p {
  margin-bottom: 0;
  color: #0081C7;
  font-size: 15px;
  margin-left: 10%;
  text-align: end;
}

.copy-right a {
  color: #0081C7;
  padding-left: 10px;
}
.call-ad {
  padding: 0;
  margin: 0 0 30px;
}

.call-ad li {
  display: block;
  margin-bottom: 10px;
}

.call-ad li a {
  color: #0081C7;
  text-decoration: none;
}



/* CommonBannerImage */
.text-center {
  text-align: center!important;
  text-transform: uppercase;
  color: #E56018;
}




/* blog */
.grid-whyus-container > div{
  margin-top: 20px;
  display: inline-flex;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-left: 5%;
}

.blog-1 {
  background: #fff;
  padding: 29px 37px 32px 32px;
  margin-top: 38px;
  border: none;
  border-radius: 30px;
  height: 70vh;
}
.blog-1 h5 {
  text-align: center;
  margin-bottom: 20px;
  color: #E56018;
  padding: 10px;
  text-transform: uppercase;
}
.blog-2 h5 {
  text-align: center;
  margin-bottom: 20px;
  color: #E56018;
  padding: 10px;
  text-transform: uppercase;
}

.blog-1 p {
  padding: 10px;
  text-align: justify;
  color: gray;
}
.blog-2 p {
  padding: 10px;
  text-align: justify;
  color: gray;
}

.blog-1:hover{
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}
.blog-2:hover{
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  cursor: pointer;
}

.blog-2{
  background: #fff;
  padding: 29px 37px 32px 32px;
  margin-top: 38px;
  border: none;
  border-radius: 30px;
  /* margin-top: -58px; */
}

.help-with-sec{
  background: #fff;
  /* padding: 5%; */
}



/* contact from */

.hm-contactus-sec {
  color: #333;
  display: block;
  clear: both;
  position: relative;
  padding-top: 50px;
  padding-bottom: 0px;
  overflow: hidden;
}

.hm-contactus-sec-two{
   background: #bab9b930;
   color: #333;
   display: block;
   clear: both;
   position: relative;
   padding-top: 50px;
   padding-bottom: 0px;
   overflow: hidden;
}


.hm-contactus-title {
  padding-bottom: 80px;
}

.hm-contactus-sec h2 {
  font-size: 35px;
  position: relative;
  text-align: center;
  color: #E56018;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 10px;
}
.hm-contactus-sec h2 span {
  color: #E56018;
  font-weight: 700;
}
.hm-contactus-sec  h2:after {
  content: '';
  display: block;
  width: 100px;
  height: 3px;
  background: #E56018;
  left: 0;
  right: 0;
  margin: 4px auto 0;
  position: absolute;
  top: 100%;
}
.hm-contactus-title p {
  margin-bottom: 0;
  line-height: 2;
  font-size: 14px;
  text-align: center;
}

.hm-contactus-sec .group {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

input.form-control,textarea.form-control{
  font-size: 12px;
  font-style: italic;
  padding: 10px;
  display: block;
  width: 100%;
  height: 45px;
  border: none;
  background-color: transparent;
  color: #222;
  border-radius: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  outline: none !important;
  margin-bottom: 20px;
}

.btn-send {
  color: #fff;
  background-color: #E56018;
  border-color: #E56018;
  height: 60px;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.btn-send:hover {
  background-color: #E56018;
  border-color: #E56018;
  color: #fff;
}



.bg-athens-gray-inner:before {
  content: '';
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 200vw;
  z-index: -1;
  background: #f2f2f5;
  height: 94vh;
}

.contact_form {
  padding: 35px 20px;
  background: #fff;
  box-shadow: 0 0 4px 2px rgba(174, 174, 174, .2);
  position: relative;
  z-index: 3;
  margin-top: 80px;
}

.address p {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 18px;
  font-size: 20px;
  font-weight: 600;
}
.address ul li {
  list-style: none;
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 20px;
}

/* countdown */

.countdown {
  text-align: center;
}

.countdown-text {
  margin-top: 20%;
  font-size: 3rem;
  color: #333;
}

.countdown-info {
  margin-top: 1rem;
  color: #777;
}

.countdown-timer {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-left: 48%;
  border: 6px solid #5cb85c;
  border-top-color: transparent;
  animation: countdown-animation 5s linear forwards;
}

@keyframes countdown-animation {
  to {
    transform: rotate(360deg);
  }
}


/* payment */
.withgst, .withoutgst {
  margin-left: 25%;
}


/* common card */
.card-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}
.card-container a{
  text-decoration: none;
}



.card1 h3 {
  color: #262626;
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
  text-align: center;
  margin-top: 4px;
}

.card1 p {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
  text-align: justify;
} 

.card1 p.small {
  font-size: 14px;
  text-align: justify;
  margin-top: 10%;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #E56018;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
}

.card1 {
  display: block;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  padding: 15%;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  height: 280px;
  width: 100%;
  margin-top: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 25px;
}
.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #E56018;
  height: 60px;
  width: 32px;
  border-radius: 35px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}
.card1:hover:before {
  transform: scale(21);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}
.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

.mobile-view-hero-btn{
  display: none;
}


/* Responsive */

@media (max-width: 450px) {
  .topbar { 
    display: none;
  }
  nav.bg-body-tertiary.topbar-nav.navbar.navbar-expand-md.navbar-light {
    display: none;
  } 
  .text-white {
    text-align: center;
    color: #fff;
  }
  
  .text-white p {
    margin: 0;
    margin-top: 26px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.text-white .mobile-view-hero-btn {
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #fff;
  font-size: large;
  font-weight: 700;
  display: block;
  margin-left: 5rem !important;
  background: transparent;
}
.text-white .mobile-view-hero-btn:hover{
  color: #fff;

}
.hm-about-sec h2 {
  font-size: 23px;
}
.hm-about-sec h2:after {
  background: none;
}
.hm-about-sec p {
  font-size: 20px;
  margin: 7px;
  text-align: justify;
  line-height: 40px;
  padding: 0;
}
.about-list {
  list-style: none;
  padding: 0;
  margin-top: -30%;
}
ul.about-list a {
  text-decoration: none !important;
}
.about-list li {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 7px;
}
.aboutimage {
  margin-top: 42px;
  margin-bottom: 19px;
  border-radius: 30px;
  margin-left: 0;
  width: 100%;
}
.loader-text {
  font-size: 1.5rem !important;
}
.circle{
  margin-top: 55% !important;
}
.hm-services-sec {
  padding-top: 40px;
}
.hm-services-sec h2 {
  font-size: 23px;
}
.hm-services-sec h2:after {
  background: none;
}
.card-container .card a:hover {
  transform: translate(2px, -10px);
  border-color: #E56018;
  border-radius: 15px;
  height: 340px;
}

.card--display {
  margin-top: 46px;
}
.card-container .card a {
  padding: 15px;
}

.supportcard .icon {
  font-size: 47px;
  margin-top: 74px;
}
.supportcard:hover .icon {
  background-position: -120px;
  transition: all 0.3s ease;
  margin-top: -57%;
}

.card-container {
  margin-left: 0px;
}
.grid-support-container > div {
  margin-top: 39px;
  display: flow;
  margin-left: 4%;
}
.supportcard {
  margin-bottom: 30px;
}
.hm-services-title p {
  font-size: 16px;
  padding: 19px;
  text-align: justify;
}
.blog-1 {
  height: 120vh;
}
.hm-services-sec p {
  padding: 20px;
  text-align: justify;
  font-size: 16px;
  line-height: 30px;
}
.supportcard:hover {
  height:470px !important;
}
.overlay {
  left: -11px;
}
.support-Limited-card {
  margin-left: 0px;
}
img.img-fluid.QuickEnquiryimg {
  margin-left: 0% !important;
}
}
.ant-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin-left: -19px;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #fafcfe;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: -19px;
}

.card1 {
  margin: 0px;
  margin-bottom: 20px;
  height: 370px;
}


/* Loader page */

.patterns {
  height: 100vh;
}

svg text {
  letter-spacing: 10px; /* Adjust letter-spacing for smaller screens */
  font-size: 45px; /* Use viewport units for responsive font size */
  font-weight: 400;
  stroke: #0C0649;
  stroke-width: 5; /* Adjust stroke width for smaller screens */
  text-transform: uppercase;
  /* animation: textAnimate 5s infinite alternate; */
}

/* Define media query for screens with a maximum width of 450px */
@media only screen and (max-width: 450px) {
  svg text {
    letter-spacing: 1px; 
    font-size: 12px; 
    stroke-width:1; 
  }
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
    fill: hsl(195, 10%, 84%);
  }
  100% {
    stroke-dasharray: 50% 0;
    stroke-dashoffset: -20%;
    fill: hsla(0, 88%, 50%, 0);
  }
}


.circle {
  height: 200px;
  border-radius: 50%;
  background: transparent;
  position: relative;
  animation: pulse 2s infinite;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


.loader-text {
  text-align-last: center;
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
}



/* Whyus Card */

.why-us{
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-left: 8%;
}
.why-card {
  flex: 1;
  margin: 10px;
}
/* .face {
  width: 100%;
  height: 300px;
}  */
.why-us .why-card{
  position: relative;
  cursor: pointer;
}

.why-us .why-card .face{
  width: 200px;
  height: 200px;
  transition: 0.5s;
}
.why-us .why-card .face.face1{
  position: relative;
  background: #E56018;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
  color: #fff;
}
.why-us .why-card:hover .face.face1{
  background: #E35C18;
  transform: translateY(0);
}
.why-us .why-card .face.face1 .content{
  transition: 0.5s;
}
.why-us .why-card:hover .face.face1 .content{
  opacity: 1;
}

.why-us .why-card .face.face1 .content img{
  max-width: 100px;
}

.why-us .why-card .face.face1 .content h3{
  margin: 10px 0 0;
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
}
.why-us .why-card .face.face2{
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  transform: translateY(-100px);
  text-align: center;
}
.why-us .why-card:hover .face.face2{
  transform: translateY(0);
}

.why-us .why-card .face.face2 .content p{
  margin: 0;
  padding: 0;
}
.why-us .why-card .face.face2 .content a{
  margin: 15px 0 0;
  display:  inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #E56018;
  padding: 5px;
  border: 1px solid #333;
}

.why-us .why-card .face.face2 .content a:hover{
  background: #333;
  color: #fff;
}

.what-us-text p {
  font-size: 16px;
  margin: 0;
  text-align: justify;
  padding: 20px;
  letter-spacing: 1px;
  line-height: 30px;
}




@media screen and (max-width: 768px) {
  .why-us{
    flex-wrap: wrap;
  }
  .why-card {
    flex-basis: calc(50% - 20px);
  }
}

@media screen and (max-width: 480px) {
  .why-card {
    flex-basis: calc(100% - 20px);
  }
  .why-us .why-card {
    margin-left: 8%;
  }
  .why-us .why-card .face{
    width: 280px;
  }

}

/* tax-calculator */


.gst-calculator {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input,
.input-group input[type="radio"] {
  margin-right: 10px;
}

.radio-group div {
  display: flex;
  align-items: center;
}

.input-group input[type="number"],
.input-group input[type="radio"] {
  appearance: none;
}

.input-group input[type="number"]::-webkit-inner-spin-button,
.input-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group input[type="number"] {
  -moz-appearance: textfield;
}

.input-group input[type="number"]::-webkit-outer-spin-button,
.input-group input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group input[readonly] {
  background-color: #f9f9f9;
  cursor: not-allowed;
}


/* call schedule */

.calender {
  margin-left: 45%;
}
.booking-from p{
  text-align: center;
  font-size: medium;
  font-weight: 700;
  text-transform: uppercase;
}

.booking-from{
  padding: 2rem 1rem 0;
}


.booking-from .input {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;
}
.booking-from .input + .input {
  margin-top: 1rem;
}

.booking-from .input-label {
  color: #8597a3;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}

.booking-from .input-field {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.125rem;
  padding: 0.25rem 0;
}

.booking-from .input-field:focus, .input-field:valid {
  outline: 0;
  border-bottom-color: #E56018;
}
.booking-from .input-field:focus + .input-label, .input-field:valid + .input-label {
  color: #E56018;
  transform: translateY(-1.5rem);
}


.booking-from .action {
  margin-top: 1rem;
}

.booking-from .action-button {
  font: inherit;
  font-size: 1.25rem;
  padding: 1em;
  width: 100%;
  font-weight: 500;
  background-color: #E56018;
  border-radius: 6px;
  color: #FFF;
  border: 0;
  margin-top: 1.5rem;
}
.booking-from .action-button:focus {
  outline: 0;
}